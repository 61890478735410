import React from 'react'
import { Link } from 'gatsby'
import { Row, Col } from 'react-bootstrap'
import DateBox from '../../components/misc/date-box'
import ArrowLink from '../../components/misc/arrow-link'
// import OYAddToCalendar from '../../components/misc/add-to-calendar'
import moment from 'moment-timezone'
import GPTUnit from '../../components/misc/gpt-unit'

import loadable from '@loadable/component'
const OYAddToCalendar = loadable(() =>
  import('../../components/misc/add-to-calendar')
)

class ArticleSummaryCard extends React.Component {
  constructor(props) {
    super(props)

    const { article, linkText, headline, teaser } = props

    this.state = {
      article,
      linkText,
      headline,
      teaser,
    }
  }

  componentWillUnmount() {}

  render() {
    return (
      <div className="event-summary-card">
        <Link
          to={`/articles/${this.state.article.slug}`}
          className="text-decoration-none"
        >
          <h3
            className="event-summary-name"
            style={{
              backgroundColor: `#${this.state.article.category.colorPalette.secondaryColor}`,
            }}
          >
            {this.state.headline
              ? this.state.headline
              : this.state.article.title}{' '}
            {/* {this.props.article.gptAdUnit ? (
              <GPTUnit {...this.props.article.gptAdUnit} />
            ) : null} */}
          </h3>
        </Link>
        <div className="event-summary-card-details">
          <div
            className="event-summary-content"
            style={{
              backgroundColor: `#${this.state.article.category.colorPalette.primaryColor}`,
            }}
          >
            <p className="d-md-block d-none">
              {this.state.teaser
                ? this.state.teaser
                : this.state.article.teaser.teaser}
            </p>

            <ArrowLink
              color="f9d021"
              to={`/articles/${this.state.article.slug}`}
            >
              {this.state.linkText ? this.state.linkText : 'Read More'}
            </ArrowLink>
          </div>
        </div>
      </div>
    )
  }
}

export default ArticleSummaryCard
