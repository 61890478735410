import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Carousel, Container, Row, Col, Form, Button } from 'react-bootstrap'
import get from 'lodash/get'
import lottie from 'lottie-web'
import carouselAnimation from '../../animations/carousel-brand-overlay.json'
// import Slide from './slide'

import CarouselBrandSVG from '../../../static/images/carousel-brand-overlay.svg'
import EventSummaryCard from '../../components/events/summary-card'
import ArticleSummaryCard from '../../components/articles/summary-card'
import { GatsbyImage } from 'gatsby-plugin-image'

class HeroCarousel extends React.Component {
  constructor(props) {
    super(props)
    this.onSlide = this.onSlide.bind(this)
    this.carouselAnimationRefs = []
  }
  // componentDidMount() {
  //   lottie.loadAnimation({
  //     container: this.animationContainer.current,
  //     animationData: animation,
  //     renderer: 'svg',
  //     loop: true,
  //     autoplay: true,
  //   })
  // }

  onSlide(index, direction) {
    // lottie.loadAnimation({
    //   container: this.carouselAnimationRefs[index].current,
    //   animationData: carouselAnimation,
    //   renderer: 'svg',
    //   loop: false,
    //   autoplay: true,
    // })
  }
  render() {
    return (
      <StaticQuery
        query={graphql`
          query CarouselSlidesQuery {
            allContentfulCarouselSlide(
              filter: { contentful_id: { ne: "2mG6roYmyKsUh6r0Lqa40o" } }
              sort: { fields: [position], order: ASC }
            ) {
              edges {
                node {
                  heroImage {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                  id
                  headline
                  teaser
                  heroImagePositioning
                  linkToContent {
                    ... on ContentfulEvent {
                      slug
                      name
                      startDate
                      endDate
                      calendarInviteTitle
                      colorPalette {
                        primaryColor
                        secondaryColor
                        backgroundColorLight
                      }
                      teaser {
                        childMarkdownRemark {
                          html
                        }
                        teaser
                      }
                      video {
                        isLiveStream
                      }
                      gptAdUnit {
                        slot
                        width
                        height
                        fullColor
                        lede
                      }
                      sys {
                        contentType {
                          sys {
                            id
                          }
                        }
                      }
                    }
                    ... on ContentfulArticle {
                      slug
                      title
                      teaser {
                        teaser
                      }
                      category {
                        name
                        icon {
                          file {
                            details {
                              image {
                                height
                                width
                              }
                            }
                            url
                          }
                        }
                        colorPalette {
                          primaryColor
                          secondaryColor
                          backgroundColorLight
                        }
                      }
                      sys {
                        contentType {
                          sys {
                            id
                          }
                        }
                      }
                    }
                  }
                  linkToUrl
                  linkText
                }
              }
            }
          }
        `}
        render={(data) => {
          const slides = get(data, 'allContentfulCarouselSlide.edges')
          return (
            <Carousel className="hero-carousel" onSlide={this.onSlide}>
              {slides.map(({ node }) => {
                const _ref = React.createRef()
                this.carouselAnimationRefs.push(_ref)
                return (
                  <Carousel.Item
                    key={node.id}
                    className="hero-carousel-item"
                    data-interval="false"
                  >
                    <GatsbyImage
                      image={node.heroImage.gatsbyImageData}
                      className="d-block w-100 h-100"
                      alt=""
                      imgStyle={{
                        objectPosition:
                          node.heroImagePositioning || 'left center',
                      }}
                    />
                    <Carousel.Caption>
                      {node.linkToContent ? (
                        <Row>
                          <Col lg={{ span: 8, offset: 4 }}>
                            {node.linkToContent.sys.contentType.sys.id ===
                            'event' ? (
                              <EventSummaryCard
                                event={node.linkToContent}
                                linkText={node.linkText}
                                headline={node.headline}
                                teaser={node.teaser}
                              />
                            ) : node.linkToContent.sys.contentType.sys.id ===
                              'article' ? (
                              <ArticleSummaryCard
                                article={node.linkToContent}
                                linkText={node.linkText}
                                headline={node.headline}
                                teaser={node.teaser}
                              />
                            ) : null}
                          </Col>
                        </Row>
                      ) : (
                        <>
                          <h3>{node.headline}</h3>
                          <p>{node.teaser}</p>
                          {node.linkText &&
                          (node.linkToContent || node.linkToUrl) ? (
                            <Button
                              variant="outline-light"
                              href={
                                node.linkToContent
                                  ? `/${pluralize(
                                      node.linkToContent.sys.contentType.sys.id
                                    )}/${node.linkToContent.slug}`
                                  : node.linkToUrl
                              }
                            >
                              {node.linkText}
                            </Button>
                          ) : null}
                        </>
                      )}
                    </Carousel.Caption>
                  </Carousel.Item>
                )
              })}
            </Carousel>
          )
        }}
      />
    )
  }
}
export default HeroCarousel
