import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Carousel, Container, Row, Col, Form, Button } from 'react-bootstrap'
import get from 'lodash/get'

class Ticker extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      visible: false,
      repeatTimes: 1,
      messageWidth: null,
    }

    this.tickerContainerRef = React.createRef()
    this.tickerInnerRef = React.createRef()

    this.setTickerSize = this.setTickerSize.bind(this)
  }

  setTickerSize() {
    // how many to fill the space * 2
    const repeatTimes =
      Math.ceil(
        this.tickerContainerRef.current.offsetWidth / this.state.messageWidth
      ) * 2

    this.setState({
      visible: true,
      repeatTimes,
    })
  }

  componentDidMount() {
    this.setState(
      {
        messageWidth: this.tickerInnerRef.current.getBoundingClientRect().width,
      },
      () => {
        this.setTickerSize(this.state.messageWidth)
        window.addEventListener('resize', this.setTickerSize)
      }
    )
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.setTickerSize)
    }
  }

  render() {
    const _this = this
    return (
      <StaticQuery
        query={graphql`
          query TickerQuery {
            allContentfulTickerMessage(
              filter: { contentful_id: { ne: "Ueex5yF2pwakBT2ocko3u" } }
              sort: { fields: [position], order: ASC }
            ) {
              edges {
                node {
                  message
                  position
                }
              }
            }
          }
        `}
        render={(data) => {
          let messages = get(data, 'allContentfulTickerMessage.edges')
          if (messages.length) {
            // repeat 10 times so we can fill the space
            messages = [].concat.apply(
              [],
              Array(this.state.repeatTimes).fill(messages)
            )

            return (
              <div ref={_this.tickerContainerRef} className="ticker-container">
                <div
                  ref={_this.tickerInnerRef}
                  className={`ticker-inner ${
                    _this.state.visible ? 'animated' : ''
                  }`}
                >
                  {messages.map(({ node }, i) => {
                    return (
                      <div key={i} className="h4">
                        {node.message}
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          } else {
            return <></>
          }
        }}
      />
    )
  }
}
export default Ticker
