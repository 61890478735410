import React from 'react'
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types'
// import { getRichTextEntityLinks } from '@contentful/rich-text-links'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

const Bold = ({ children }) => <strong>{children}</strong>
// const Text = ({ children }) => <p>{children}</p>

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <strong>{text}</strong>,
    [MARKS.CODE]: (text) => (
      <a className="embedly-card" href={text}>
        {text}
      </a>
    ),
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
    [BLOCKS.CODE]: (node, children) => (
      <Text className="text-purple">{children}</Text>
    ),
    [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
      try {
        const contentType = node.data.target.fields.file['en-US'].contentType
        const url = node.data.target.fields.file['en-US'].url
        if (contentType.match(/^image/)) {
          return <img src={url} />
        } else {
          return null
        }
      } catch {
        return null
      }
    },
    [INLINES.ASSET_HYPERLINK]: (node, children) => {
      let url
      try {
        url = node.data.target.fields.file['en-US'].url
      } catch {
        url = null
      }
      return <a href={url}>{children}</a>
    },
  },
}

export default ({ raw }) => (
  <>{documentToReactComponents(JSON.parse(raw), options)}</>
)
