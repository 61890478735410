import React, { useContext } from 'react'
import { graphql, Link } from 'gatsby'
import ReactDOM from 'react-dom'
import get from 'lodash/get'
import Modal from '../components/layout/modal'
import { Container, Row, Col } from 'react-bootstrap'
import Logo from '../components/layout/logo'

import PeopleList from '../components/people/list'
import lottie from 'lottie-web'
import aboutAnimation from '../animations/about-brand.json'
import HeroCarousel from '../components/carousel'
import Ticker from '../components/ticker'
import LayoutContext from '../components/layout/context'
import { withCookies, Cookies } from 'react-cookie'

import 'intersection-observer' // optional polyfill
import Observer from '@researchgate/react-intersection-observer'

class Home extends React.Component {
  static contextType = LayoutContext

  constructor(props) {
    super(props)

    const categories = get(props, 'data.allContentfulCategory.edges').filter(
      ({ node: category }) => {
        return !!category.mapTilePlaceholder
      }
    )

    const { cookies } = props
    this.state = {
      animation: null,
      modalViewed: cookies.get('modal-viewed') || false,
      categories,
    }
    this.aboutAnimationContainer = React.createRef()
    this.handleVisibilityChange = this.handleVisibilityChange.bind(this)
  }

  componentWillMount() {
    this.context.set({
      category: null,
      meta: get(this, 'props.data.contentfulMetaTags'),
      mapOpen: true,
    })
  }

  componentDidMount() {
    if (!this.state.modalViewed) {
      const { cookies } = this.props
      cookies.set('modal-viewed', true, { path: '/' })
    }
    // const animation = lottie.loadAnimation({
    //   container: this.aboutAnimationContainer.current,
    //   animationData: aboutAnimation,
    //   renderer: 'svg',
    //   loop: false,
    //   autoplay: false,
    // })
    // animation.setSpeed(0.5)

    // this.setState({
    //   animation,
    // })
  }

  componentWillUnmount() {}

  handleVisibilityChange(e) {
    if (e.isIntersecting) {
      this.state.animation.play()
    } else {
      this.state.animation.stop()
    }
  }

  render() {
    return (
      <Modal isOpen={!this.state.modalViewed}>
        <Row>
          <Col className="text-center pb-4 pb-md-0" md={3}>
            <Logo noLink width="150" />
          </Col>
          <Col>
            <h5>
              Welcome to One Yard. Your preeminent HBCU destination, giving you
              the best of Homecoming Season in one immersive experience.
            </h5>
            <p>
              This gathering space of virtual events and engaging content is
              designed to connect, unify, and celebrate like never before
              despite this year's challenges. Because Black excellence don’t
              quit. So, get ready and tell a friend to tell a friend. Because
              there’s no place like homecoming.
            </p>
            <p>
              Follow us around campus as we explore the best of Black
              Excellence:
            </p>
            {this.state.categories.map(({ node: category }) => (
              <Link
                key={category.slug}
                className="modal-category-link"
                to={`/categories/${category.slug}`}
                style={{ color: `#${category.colorPalette.primaryColor}` }}
              >
                <div
                  className="modal-category-icon"
                  style={{
                    backgroundColor: `#${category.colorPalette.primaryColor}`,
                  }}
                >
                  <img
                    src={category.icon.file.url}
                    alt={category.name}
                    width={category.icon.file.details.image.width * 0.7}
                    height={category.icon.file.details.image.height * 0.7}
                  />
                </div>
                <div className="modal-category-text">
                  <strong>{category.name}</strong> - {category.headline}
                </div>
              </Link>
            ))}
          </Col>
        </Row>
      </Modal>
    )
  }
}

export default withCookies(Home)

export const pageQuery = graphql`
  query HomeQuery {
    contentfulMetaTags(pagePath: { eq: "/" }) {
      title
      image {
        gatsbyImageData(width: 1200, height: 630)
      }
      url
      description {
        description
      }
    }
    allContentfulCategory(
      filter: { contentful_id: { ne: "5FkcKCGaTq2LURVvai82vP" } }
      sort: { fields: [position], order: ASC }
    ) {
      edges {
        node {
          name
          slug
          headline
          colorPalette {
            primaryColor
            secondaryColor
          }
          icon {
            file {
              details {
                image {
                  height
                  width
                }
              }
              url
            }
          }
          iconBW {
            file {
              details {
                image {
                  height
                  width
                }
              }
              url
            }
          }
          mapTilePlaceholder {
            gatsbyImageData(width: 1200, height: 1200)
          }
        }
      }
    }
  }
`
