import React from 'react'
// import { Card } from 'react-bootstrap'
import { Link } from 'gatsby'
import { Row, Col } from 'react-bootstrap'
import DateBox from '../../components/misc/date-box'
import ArrowLink from '../../components/misc/arrow-link'
// import OYAddToCalendar from '../../components/misc/add-to-calendar'
import moment from 'moment-timezone'
import GPTUnit from '../../components/misc/gpt-unit'

import loadable from '@loadable/component'
const OYAddToCalendar = loadable(() =>
  import('../../components/misc/add-to-calendar')
)

class EventSummaryCard extends React.Component {
  // export default ({ event }) => {
  constructor(props) {
    super(props)

    const { event, linkText, headline, teaser } = props
    const startDate = moment.tz(event.startDate, 'US/Eastern')
    const endDate = moment.tz(event.endDate, 'US/Eastern')
    const isLive =
      event.video && moment.tz('US/Eastern').isBetween(startDate, endDate)

    const isPlayable =
      isLive || (endDate.isBefore() && event.video && !event.video.isLiveStream)
    // this.state = {}

    this.state = {
      isLive,
      isPlayable,
      event,
      linkText,
      headline,
      teaser,
      startDate,
      endDate,
    }
  }

  componentDidMount() {
    // window.setTimeout(() => {})
  }

  componentWillUnmount() {}

  render() {
    const startDate = moment.tz(this.props.event.startDate, 'US/Eastern')
    const endDate = moment.tz(this.props.event.endDate, 'US/Eastern')
    return (
      <div className="event-summary-card">
        <Link
          to={`/events/${this.props.event.slug}`}
          className="text-decoration-none"
        >
          <h3
            className="event-summary-name"
            style={{
              backgroundColor: `#${this.props.event.colorPalette.secondaryColor}`,
            }}
          >
            {this.state.headline ? this.state.headline : this.state.event.name}{' '}
            {this.props.event.gptAdUnit ? (
              <GPTUnit {...this.props.event.gptAdUnit} />
            ) : null}
          </h3>
        </Link>
        <div className="event-summary-card-details">
          {startDate.isAfter() ? (
            <DateBox
              dateString={this.props.event.startDate}
              backgroundColor={'FFF'}
              primaryColor={this.props.event.colorPalette.primaryColor}
              secondaryColor={this.props.event.colorPalette.secondaryColor}
            />
          ) : null}

          <div
            className="event-summary-content"
            style={{
              backgroundColor: `#${this.props.event.colorPalette.primaryColor}`,
            }}
          >
            {this.state.teaser ? (
              <p className="d-md-block d-none">{this.state.teaser}</p>
            ) : (
              <div
                className="d-md-block d-none"
                dangerouslySetInnerHTML={{
                  __html: this.props.event.teaser.childMarkdownRemark.html,
                }}
              />
            )}

            {startDate.isAfter() ? (
              <OYAddToCalendar
                event={{
                  title:
                    this.props.event.calendarInviteTitle ||
                    this.props.event.name,
                  description: this.props.event.teaser.teaser,
                  location: `https://oneyard.co/events/${this.props.event.slug}`,
                  startTime: this.props.event.startDate,
                  endTime: this.props.event.endDate,
                }}
                color="f9d021"
                // arrowColor={event.colorPalette.primaryColor}
                buttonLabel="Add to Calendar"
                className="arrow-link"
              />
            ) : (
              <ArrowLink color="f9d021" to={`/events/${this.props.event.slug}`}>
                {this.state.linkText
                  ? this.state.linkText
                  : this.state.isLive
                  ? 'Watch Live'
                  : this.state.isPlayable
                  ? 'Watch Video'
                  : 'Stay tuned for the replay!'}
              </ArrowLink>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default EventSummaryCard
