import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'

// const modalRoot = document.getElementById('modal-container')

const Modal = ({ isOpen, children, onClose }) => {
  const [open, setOpen] = useState(isOpen)

  return open && typeof document !== 'undefined'
    ? ReactDOM.createPortal(
        <div className="modal-overlay">
          <div className="modal-inner">
            <button
              className="modal-close-button"
              onClick={() => {
                setOpen(false)
                if (typeof onClose === 'function') {
                  onClose()
                }
              }}
              type="button"
            >
              <span>Close</span>
            </button>
            {children}
          </div>
        </div>,
        document.getElementById('modal-container')
      )
    : null
}
export default Modal
